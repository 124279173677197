<template>
  <section class="section sedes">
    <div class="columns">
      <div class="column">
        <figure class="image">
          <img class="img" alt="RPG hombros" src="../assets/sedes/centro.jpg" />
        </figure>
      </div>
      <div class="column is-one-quarter">
        <div class="content">
          <h3 class="title is-3">Sede Nva Córdoba</h3>
          <ul>
            <li>Reeducación Postural Global.</li>
            <li>Charlas Informativas Institucionales sobre Postura y Ergonomia.</li>
            <li>Estudio Digital de la Pisada y Confección de Plantillas.</li>
            <li>Punción Seca y K Taping</li>
            <li>Osteopatia.</li>
            <li>Masoterapia.</li>
            <li>Streiching Global Activo (modo on line)</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="columns">
      
      <div class="column is-one-quarter">
        <div class="content">
          <h3 class="title is-3">La Calera</h3>
          <ul>
            <li>Reeducación Postural Global.</li>
            <li>Charlas Informativas Institucionales sobre Postura y Ergonomia.</li>
            <li>Estudio Digital de la Pisada y Confección de Plantillas.</li>
            <li>Punción Seca y K Taping</li>
            <li>Osteopatia.</li>
            <li>Masoterapia.</li>
            <li>Streiching Global Activo (modo on line)</li>
          </ul>
        </div>
      </div>
      <div class="column">
        <figure class="image">
          <img class="img" alt="RPG hombros" src="../assets/sedes/calera.jpg" />
        </figure>
      </div>
    </div>
  </section>
</template>
